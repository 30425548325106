import {mapState, mapActions} from 'vuex'
import {required} from 'vuelidate/lib/validators'

export default {
    metaInfo() {
        return {
            title: `${this.title.primary} - ${this.title.secondary}`
        }
    },

    data() {
        return {
            preloader: false,

            title: {
                primary: 'Задания',
                secondary: 'Новое задание',
            },

            breadcrumbsRouteList: [
                {
                    title: 'Загрузка заданий',
                },
                {
                    title: 'Новое задание',
                }
            ],

            form: {
                type: '',
                name: '',
                project_id: '',
                operation_id: ''
            },

            fileType: 'csv',

            fileTypes: [
                {
                    id: 'csv',
                    name: 'CSV'
                },
                {
                    id: 'yml',
                    name: 'YML'
                },
            ],

            disabledForm: false,

            fileUploadError: false
        }
    },

    validations: {
        form: {
            type: {
                required
            },

            name: {
                required
            },

            project_id: {
                required
            },

            operation_id: {
                required
            }
        }
    },

    computed: {
        ...mapState({
            taskTypes: state => state.dashboard.tasks.taskTypes,
            projects: state => state.dashboard.tasks.projects,
            config: state => state.config,
            profile: state => state.profile
        })
    },

    methods: {
        ...mapActions({
            getTaskTypes: 'dashboard/tasks/getTasksConfig',
            getProjects: 'dashboard/tasks/getProjects',
            createTask: 'dashboard/tasks/createTask'
        }),

        /* Загрузка списка проектов */
        lazyLoadList (data) {
            return this.getProjects(data)
        },

        /* Загрузка файла */
        uploadFile (file) {
            if (this.profile.demo_mode) {
                return
            }
            const formData = new FormData()
            formData.append('file', file)
            this.$store.dispatch('fileUpload', formData).then(response => {
                try {
                    if (!this.fileUploadError && response.data.result) {
                        this.form.operation_id = response.data.operation_id
                        this.$set(this, 'fileUploadError', false)
                    } else {
                        this.$set(this, 'fileUploadError', false)
                        throw new Error()
                    }
                } catch (_) {
                }
            })
        },

        /* Удалить загруженный файл */
        deleteFile () {
            if (this.form.operation_id) {
                this.form.operation_id = ''
            }
            this.$refs.dropzone[0].$children[0].removeAllFiles()
        },

        alertFileTypeError () {
            this.$set(this, 'fileUploadError', true)
            this.deleteFile()
            this.$notify({type: 'error', title: 'Неверный формат файла'})
        },

        downloadExample () {
            let url = `${this.config.api['main']}/frequency/yandex/history/${this.fileType}/download`
            // window.location.replace(url)
        },

        async submit () {
            try {
                let form = JSON.parse(JSON.stringify(this.form))

                await this.createTask(form)
                    .then(() => {
                        this.$notify({ type: 'success', title: 'Задание создано' })
                        this.$set(this, 'form', {
                            type: '',
                            name: '',
                            project_id: '',
                        })
                        this.deleteFile()
                    }, error => {
                        if(typeof error === 'object') {
                            for (let item in error) {
                                this.$notify({ type: 'error', title: error[item] })
                            }
                        } else {
                            this.$notify({ type: 'error', title: error });
                        }
                    })
            } catch (e) {
                this.$notify({ type: 'error', title: e.message })
            }
        }
    },

    async created() {
        await this.getTaskTypes()
    }
}
